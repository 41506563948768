/**
 * String constants used on the various pages.
 * Not all strings used on the page need to be placed here, but if a string is used in multiple places
 * then generally it is good to place it here so the string exists only once.
 */
export const commonString = {
    siteName: 'Microsoft Goods and Services Receipting',
    poNumber: 'PO Number',
    poPlusOptionalLineNumber: 'PO (+ optional Line) Number',
    poLineNumber: 'PO + Line Number',
    lineNumber: 'Line Number',
    line: 'Line',
    supplierNumber: 'Supplier Number', // Note using the term supplier rather than vendor.
    supplierName: 'Supplier Name',
    supplierNameNumber: 'Supplier Name/Number',
    suppliers: 'Suppliers',
    companyCode: 'Company Code',
    companyCodes: 'Company Codes',
    allCompanyCodes: 'All company codes',
    currencyCode: 'Currency Code',
    poApprover: 'PO Approver',
    poApproverAlias: 'PO Approver Alias',
    poOwner: 'PO Owner',
    poOwnerAlias: 'PO Owner Alias',
    invoiceApprover: 'Invoice Approver',
    invoiceApproverAlias: 'Invoice Approver Alias',
    currency: 'Currency',
    dateCreatedFrom: 'Date Created From',
    dateCreatedTo: 'Date Created To',
    deliveryDate: 'Delivery Date',
    deliveryDateFrom: 'Delivery Date From',
    deliveryDateTo: 'Delivery Date To',
    fiscalYear: 'Fiscal Year',
    isRetained: 'Is Retained',
    retentionExpirationDate: 'Retention Expiration Date',
    poDescription: 'PO Description',
    lineDescription: 'Line Description',
    poCreateDate: 'PO Create Date',
    lineTotal: 'Line Total',
    lineTotalUsd: 'Line Total (USD)',
    invoicedAmount: 'Invoiced Amount',
    invoicedAmountUsd: 'Invoiced Amount (USD)',
    invoicedAmountForCurrentFy: 'Total Invoiced Current FY',
    invoicedAmountForCurrentFyUsd: 'Total Invoiced Current FY (USD)',
    accrualReversalForCurrentFy: 'S2S Accrual Reversal Or New Accrual In Current FY',
    accrualReversalForCurrentFyUsd: 'S2S Accrual Reversal Or New Accrual In Current FY (USD)',
    openAmount: 'Open Amount',
    openAmountUsd: 'Open Amount (USD)',
    costCenter: 'Cost Center',
    internalOrder: 'Internal Order',
    costCenterIo: 'Cost Center/IO',
    glAccount: 'GL Account',
    purchasingClassification: 'Purchase Classification',
    profitCenter: 'Profit Center',
    profitCenterDescription: 'Profit Center Description',
    systemPostedAccrualAmount: 'S2S Open Accrual YTD Amount',
    systemPostedAccrualAmountUsd: 'S2S Open Accrual YTD Amount (USD)',
    pyaOpenAccrualAmount: 'PYA Open Amount (Static Data-30 Jun)',
    pyaOpenAccrualAmountUsd: 'PYA Open Amount (Static Data-30 Jun) (USD)',
    dedicatedPnlAccountCode: 'Dedicated P&L Account',
    originalPlImpact: '(-) Over / + Under Original P&L Impact',
    originalPlImpactUsd: '(-) Over / + Under Original P&L Impact (USD)',
    dedicatedPl: '(-) Over / + Under MCAPS/MCB Dedicated P&L Impact',
    dedicatedPlUsd: '(-) Over / + Under MCAPS/MCB Dedicated P&L Impact (USD)',
    execOrg: 'Exec Org',
    execOrgCode: 'Exec Org Code',
    channelOrgSummary: 'Channel Org Summary',
    channelOrgSummaryCode: 'Channel Org Summary Code',
    channelOrg: 'Channel Org',
    channelOrgCode: 'Channel Org Code',
    orgDetail: 'Org Detail',
    orgDetailCode: 'Org Detail Code',
    functionSummary: 'Function Summary',
    function: 'Function',
    functionDetail: 'Function Detail',
    execOrgSummary: 'Exec Org Summary',
    execOrgSummaryCode: 'Exec Org Summary Code',
    salesLocation: 'Sales Location',
    salesLocationCode: 'Sales Location Code',
    areaSummary: 'Area Summary',
    areaDesc: 'Area Description',
    region: 'Region',
    delegatedBy: 'Delegated By',
    includeReceived: 'Include Received',
    includeGoodsServices: 'Include Goods/Services',
    delegatedByPoOwner: 'Delegated By PO Owner',
    viewPosFor: 'View POs For',
    hierarchy: 'Hierarchy',
    assetTag: 'Asset Tag',
    serialNumber: 'Serial Number',
    recipientName: 'Recipient Name',
    received: 'Received',
    receivedAmount: 'Received Amount',
    receivedPercentage: 'Received Percentage',
    adjustReceivedAmount: 'Adjust Received Amount',
    receiptDate: 'Receipt Date',
    lastReceiptActionedBy: 'Last Receipted By',
    receivedQuantity: 'Received Quantity',
    isReceived: 'Is Received',
    alias: 'Alias',
    accessRights: 'Access Rights',
    startDate: 'Start Date',
    endDate: 'End Date',
    open: 'Open',
    openAwaitingResponse: 'Open (awaiting response)',
    closed: 'Closed',
    closedComments: 'Closed Comments (optional)',
    retaied: 'Retained',
    currencySelection: 'Show currency as',
    selectAtLeastOne: 'Select at least one',
    notRequired: 'Not Required',
    businessJustification: 'Business Justification',
    createdDate: 'Created Date',
    requestor: 'Requestor',
    status: 'Status',
    action: 'Action',
    selectLineItem: 'Select line item',
    selectAllLineItems: 'Select all line items',
    delete: 'Delete',
    corpApproval: 'Corporate Approval',
    stcApproval: 'Statutory Controller Approval',
    poePodAvailability: 'POE/POD Availability',
    invoiceExpectedDate: 'Invoice Expected Date',
    stcComment: 'Statutory Controller Comment',
    corpComment: 'Corp Accounting Comment',
    updateOnly: 'Update Only',
    approved: 'Approved',
    notApproved: 'Not Approved',
    yes: 'Yes',
    no: 'No',
    pendingReview: 'Pending Review',
    reversalType: 'Reversal Type',
    materialDocNumber: 'Material Document Number',
    reversalQuantity: 'Reversal Quantity',
    videoTutorialGuides: 'Video Tutorial Guides',
    videoGuideSelection: 'Choose Video:',
    notifications: 'Notifications',
    feedback: 'Feedback',
    financeAssistant: 'Finance Assistant',
    getPageLink: 'Get page link',
    totalLineItems: 'Total Line Items',
    totalOpenLineItems: 'Total Open Line Items',
    totalClosedLineItems: 'Total Closed Line Items',
    totalRetainedLineItems: 'Total Retained Line Items',
    totalOpenAmountUsd: 'Total Open Amount (USD)',
    totalOpenAccrualAmountUsd: 'Total Open Accrual Amount (USD)',
    totalInvoicedAmountUsd: 'Total Invoiced Amount (USD)',
    missingPoNumber: '[missing PO number]',
    costCategoryDedicatedGl: 'Dedicated GL',
    costCategoryGlAccount: 'GL Account',
    costCategorySubclass: 'Subclass',
    costCategoryClass: 'Class',
    costCategoryGroupName: 'Group Name',
    costCategoryLineItem: 'Line Item',
    costCategoryLineItemDetail: 'Line Item Detail',
    costCategoryShortName: 'Short Name',
    dedicatedGl: 'Dedicated GL Account',
    staticCostCenter: 'Cost Center - Static',
    staticProfitCenter: 'Profit Center - Static',
    staticIO: 'IO - Static',
    staticExecOrgSummary: 'Exec Org Summary - Static',
    staticOrgSummary: 'Org Summary - Static',
    staticOrgDetail: 'Org Detail - Static',
    staticSalesLocation: 'Sales Location - Static',
    readonly: '(read only)',
    staticGlAccount: 'GL Account - Static',
    staticGLGlAccountDescription: 'GL Account Description - Static',
    staticGLSubclassName: 'Subclass Name - Static',
    staticGLSubclassCode: 'Subclass Code - Static',
    staticGLClassDesc: 'Class Description - Static',
    staticGLClassCode: 'Class Code - Static',
    staticGLGroupNameDesc: 'Group Name Description - Static',
    staticGLGroupNameCode: 'Group Name Code - Static',
    staticGLLineItemDesc: 'Line Item Description - Static',
    staticGLLineItemCode: 'Line Item Code - Static',
    staticGLLineItemDetailDesc: 'Line Item Detail Description - Static',
    staticGLLineItemDetailCode: 'Line Item Detail Code - Static',
    shipmentUploadFileStatus: 'File Upload Status'
};
