export interface IFieldValidation {
    pattern?: string;
    maxLength?: number;
    minLength?: number;
    tooltip?: string; // Tooltip used for input field to give guidance on allowable input values. Non-input related tooltips are in tooltips.ts.
    errorMsg?: string; // This message displays underneath fields when invalid.
}

// Good sites to test regex: https://regex101.com/ or https://www.regextester.com/

export class ValidationConstants {
    public assetTag: IFieldValidation = {
        pattern: '^([a-zA-Z0-9]+|N/A|n/a)$',
        maxLength: 10,
        minLength: 0,
        tooltip: 'Enter asset tag. Alphanumeric (max 10 characters) or use N/A if not applicable.',
        errorMsg: 'Alphanumeric or N/A'
    };

    public serialNumber: IFieldValidation = {
        // Explanation of "\\\\-" at the end: Need to allow \ and - chars which need to be escaped in regex
        // with a preceeding \. So the \\ will escape and emit a single \. So this \\\\ will emit \\
        // and \\- will emit \- in the regex which will make it so \ and - are allowed.
        pattern: '^[a-zA-Z0-9#()$&+=.,?/\\\\-]+$',
        maxLength: 50,
        minLength: 0,
        tooltip: 'Enter serial number. Alphanumeric and #()$&+=.,?/\\- (max 50 characters) or use N/A if not applicable.',
        errorMsg: 'Alphanumeric or N/A'
    };

    public userAlias: IFieldValidation = {
        pattern: '^[a-zA-Z0-9._\\-]+$',
        maxLength: 50,
        minLength: 0,
        tooltip: 'Enter user alias. Alphanumeric and ._- (max 50 characters).',
        errorMsg: 'Alphanumeric only'
    };

    public userName: IFieldValidation = {
        pattern: '^[a-zA-Z0-9._\\- ]+$', // Allow for entry of whitespace (space between first and last name).
        maxLength: 50,
        minLength: 0,
        tooltip: 'Enter user name. Alphanumeric and ._- and whitespace (max 50 characters).',
        errorMsg: 'Alphanumeric and whitespace only'
    };

    public companyCode: IFieldValidation = {
        pattern: '^[0-9]*$',
        maxLength: 4,
        minLength: 4,
        tooltip: 'Enter company code. Numeric (4 digits).',
        errorMsg: 'Numeric (4 digits)'
    };

    public poNumber: IFieldValidation = {
        // Pattern supports 3 types of POs:
        // 009 or 008 (leading zeroes optional) followed by 7 digits - ex: 0091234567
        // 010 (leading zero optional) followed by 7 digits - ex: 0101234567
        // 5 followed by 9 digits - ex: 5100001745
        pattern: '(^(0{2})?(9|8){1}[\\d]{7}$)|(^(0{1})?(10){1}[\\d]{7}$)|(^(5){1}[\\d]{9}$)',
        maxLength: 10,
        minLength: 0,
        tooltip: 'Enter purchase order number.',
        errorMsg: 'Invalid purchase order number'
    };

    public lineNumber: IFieldValidation = {
        pattern: '^[\\d]{2,5}$',
        // Line number can be 2 to 5 digits.
        // So line number with leading zeroes like 00010 or 10 are both legal.
        maxLength: 5,
        minLength: 0,
        tooltip: 'Enter line number. 2 to 5 digits.',
        errorMsg: 'Invalid line number'
    };

    public poPlusOptionalLineNumber: IFieldValidation = {
        pattern: '((^(0{2})?(9|8){1}[\\d]{7})|(^(0{1})?(10){1}[\\d]{7}))(-[\\d]{2,5})?$',
        // This pattern supports:
        // - PO number starting with 009 or 008, or 010, followed by 7 digits.
        // - Optional line number after -. Line number can be 2 to 5 digits.
        //   So line number with leading zeroes like 00010 or 10 are both legal.
        maxLength: 16,
        minLength: 0,
        tooltip: 'Enter valid purchase order number (with optional line number, separated by -) number. Ex: 0100042207-00010',
        errorMsg: 'Invalid purchase order number'
    };

    public poLineNumber: IFieldValidation = {
        pattern: '^[0-9]*$',
        maxLength: 5,
        minLength: 0,
        tooltip: 'Enter purchase order line number. Numeric (max 5 digits).',
        errorMsg: 'Numeric (max 5 digits)'
    };

    public supplierNumber: IFieldValidation = {
        pattern: '^[0-9]*$',
        maxLength: 10,
        minLength: 0,
        tooltip: 'Enter supplier number. Numeric (max 10 digits).',
        errorMsg: 'Numeric (max 10 digits)'
    };

    public supplierNameOrNumber: IFieldValidation = {
        pattern: '^[a-zA-Z0-9 ._\\-\\(\\)\\/\\\\]+$',
        maxLength: 50,
        minLength: 0,
        tooltip: 'Enter supplier name or number. Alphanumeric, whitespace, and ._-()/\\ (max 50 characters).',
        errorMsg: 'Alphanumeric only'
    };

    public closedComments: IFieldValidation = {
        pattern: '^[a-zA-Z0-9 .,\\-\\\\\\/\']*$',
        maxLength: 255,
        minLength: 15,
        tooltip: 'Enter comments for close line. Alphanumeric, whitespace, and .,-\\/\' (min 15, max 255 characters).',
        errorMsg: 'Alphanumeric, whitespace, and .,-\\/\' (min 15, max 255 characters)'
    };

    public businessJustification: IFieldValidation = {
        pattern: '^[a-zA-Z0-9 .,\\-\\\\\\/\']*$',
        maxLength: 1000,
        minLength: 1,
        tooltip: 'Enter business justification. Alphanumeric, whitespace, and .,-\\/\' (max 1000 characters).',
        errorMsg: 'Alphanumeric, whitespace, and .,-\\/\' (max 1000 characters)'
    };

    public receivedAmount: IFieldValidation = {
        pattern: '^(\\d+(\\.\\d*)?)$', // A number with optional decimal portion - ex: 123.45
        maxLength: 20,
        minLength: 0,
        tooltip: 'Enter received amount. Numeric (max 20 digits).',
        errorMsg: 'Numeric (max 20 digits)'
    };

    public receivedAmountAdjust: IFieldValidation = {
        pattern: '^-?(\\d+(\\.\\d*)?)$', // A number with optional decimal portion - ex: 123.45. Allow negative.
        maxLength: 20,
        minLength: 0,
        tooltip: 'Enter adjustment amount. Numeric (max 20 digits).',
        errorMsg: 'Numeric (max 20 digits)'
    };

    public corpComment: IFieldValidation = {
        pattern: '^[a-zA-Z0-9 .,\\-\\\\\\/\']*$',
        maxLength: 500,
        minLength: 0,
        tooltip: 'Enter comments for close line. Alphanumeric, whitespace, and .,-\\/\' (max 500 characters).',
        errorMsg: 'Alphanumeric, whitespace, and .,-\\/\' (max 500 characters)'
    };

    public stcComment: IFieldValidation = {
        pattern: '^[a-zA-Z0-9 .,\\-\\\\\\/\']*$',
        maxLength: 500,
        minLength: 0,
        tooltip: 'Enter comments for close line. Alphanumeric, whitespace, and .,-\\/\' (max 500 characters).',
        errorMsg: 'Alphanumeric, whitespace, and .,-\\/\' (max 500 characters)'
    };

    public reversalQuantity: IFieldValidation = {
        pattern: '^[\\d]*$',
        maxLength: 9,
        minLength: 0,
        tooltip: 'Reversal quantity must be an amount that is received but not invoiced.',
        errorMsg: 'Invalid reversal quantity'
    };

    public materialDocNumber: IFieldValidation = {
        pattern: '[\\s\\S]+', // Match on anything.
        maxLength: 32,
        minLength: 0,
        tooltip: 'Material document number (SAP reference ID).',
        errorMsg: 'Invalid material document number'
    };

    public costCategoryShortName: IFieldValidation = {
        // Examples (seems to be mostly alphanumeric):
        //   People
        //   Inc Comp
        pattern: '^[a-zA-Z0-9]+$',
        maxLength: 50,
        minLength: 0,
        tooltip: 'Enter cost element code. Alphanumeric (max 50 characters).',
        errorMsg: 'Invalid input'
    };

    public costCategoryGlAccount: IFieldValidation = {
        // Examples (seems to be alphanumeric strings):
        //   720102
        //   Z920400
        pattern: '^[a-zA-Z0-9]+$',
        maxLength: 50,
        minLength: 0,
        tooltip: 'Enter cost element code. Alphanumeric (max 50 characters).',
        errorMsg: 'Invalid input'
    };

    public costCategoryDedicatedGl: IFieldValidation = {
        // Examples (numeric strings only):
        //   757075
        pattern: '^[0-9]+$',
        maxLength: 50,
        minLength: 0,
        tooltip: 'Enter cost element code. Numeric (max 50 characters).',
        errorMsg: 'Invalid input'
    }
}

export const validationConstants = new ValidationConstants();
