
import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Text
} from '@fluentui/react';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { ShipmentFileInfo } from '../../models/shipment/shipmentFileInfo';
import { commonStyles } from '../../common/common.styles';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { DownloadButton } from './DownloadButton';

export const supplierColumn: ICustomColumn = {
    key: 'supplierColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1=''
                headerLine2='Supplier'
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: ShipmentFileInfo) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.supplier}
            </Text>
        );
    },
    isRowHeader: true
};

export const fileColumn: ICustomColumn = {
    key: 'fileColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1=''
                headerLine2='File'
            />
        );
    },
    isResizable: false,
    minWidth: 110,
    onRender: (item: ShipmentFileInfo) => {
        return (
            <Text className={commonStyles.textCell}>
                <DownloadButton shipmentFileInfo={item} />
            </Text>
        );
    }
};

export const totalRecordsColumn: ICustomColumn = {
    key: 'totalRecordsColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Total'
                headerLine2='Records'
            />
        );
    },
    isResizable: true,
    minWidth: 70,
    onRender: (item: ShipmentFileInfo) => {
        return (
            <Text className={commonStyles.textCell}>
                { numberAsLocaleString(item.totalRecords, 0)}
            </Text>
        );
    }
};

export const statusColumn: ICustomColumn = {
    key: 'statusColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1=''
                headerLine2='Status'
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: ShipmentFileInfo) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.status}
            </Text>
        );
    }
};

export const uploadDateColumn: ICustomColumn = {
    key: 'uploadDateColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Upload'
                headerLine2='Date'
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: ShipmentFileInfo) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.uploadDate)}
            </Text>
        );
    }
};

export const uploadedByColumn: ICustomColumn = {
    key: 'uploadedByColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Uploaded'
                headerLine2='By'
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: ShipmentFileInfo) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.uploadedBy}
            </Text>
        );
    }
};

export const errorMessageColumn: ICustomColumn = {
    key: 'errorMessageColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Error'
                headerLine2='Message'
            />
        );
    },
    isResizable: true,
    minWidth: 300,
    onRender: (item: ShipmentFileInfo) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.errorMessage}
            </Text>
        );
    }
};

export const shipmentFileColumns: IColumn[] = [
    supplierColumn,
    fileColumn,
    totalRecordsColumn,
    statusColumn,
    uploadDateColumn,
    uploadedByColumn,
    errorMessageColumn
];
