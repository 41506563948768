export interface IShipmentFileInfo {
    supplier: string;
    fileName: string;
    filePath: string;
    totalRecords: number;
    status: string;
    uploadDate: Date;
    uploadedBy?: string;
    errorMessage?: string;
}

export class ShipmentFileInfo implements IShipmentFileInfo {
    public supplier: string;
    public fileName: string;
    public filePath: string;
    public totalRecords: number;
    public status: string;
    public uploadDate: Date;
    public uploadedBy?: string;
    public errorMessage?: string;

    // Below fields are not part of the server returned object, but are added for client side support.
    public clientRowKey?: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IShipmentFileInfo) {
        this.supplier = jsonData.supplier;
        this.fileName = jsonData.fileName;
        this.filePath = jsonData.filePath;
        this.totalRecords = jsonData.totalRecords;
        this.status = jsonData.status;
        this.uploadDate = new Date(jsonData.uploadDate);
        this.uploadedBy = jsonData.uploadedBy;
        this.errorMessage = jsonData.errorMessage;
    }
}
