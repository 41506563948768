import { PoType, Role, SearchOperation, SearchOperationParameter, Source } from '../../common/appEnums';
import { isNullOrUndefinedOrEmptyString } from '../../common/common.func.general';
import { AuditHistoryEvent } from '../../models/auditHistory/auditHistoryEvent';
import { PriorYearAccrualSearch, PriorYearAccrualSearchRole } from '../../models/priorYearAccrual/priorYearAccrualSearch';
import { PriorYearAccrualSearchResults } from '../../models/priorYearAccrual/priorYearAccrualSearchResults';
import { PurchaseOrderDetails } from '../../models/purchaseOrder/purchaseOrderDetails';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import { PurchaseOrderSearchResults } from '../../models/purchaseOrder/purchaseOrderSearchResults';
import { Supplier } from '../../models/domain/supplier';
import { TileSet } from '../../models/dashboard/tileSet';
import { UserProfile } from '../../models/user/userProfile';
import { appConfig } from '../../shell/appConfig';
import { getUserAlias } from '../auth/msalHelper';
import { ApiClientBase, ApiService } from './apiClientBase';
import { Company } from '../../models/domain/company';
import { PurchaseOrderLineItemPair } from '../../models/purchaseOrder/purchaseOrderLineItemPair';
import { PurchaseOrderLineClose } from '../../models/purchaseOrder/purchaseOrderLineClose';
import { ReportFile } from '../../models/report/reportFile';
import { dateAsNonUtcIsoString } from '../../common/common.func.datetime';
import { ClientNotificationData } from '../../models/clientNotification/clientNotificationData';
import { POLineAccrualProcessingDetail } from '../../models/accrualDashboard/poLineAccrualProcessingDetail';
import { POLinePriorYearAccrualProcessingDetail } from '../../models/accrualDashboard/poLinePriorYearAccrualProcessingDetail';
import { POLineReceiptAccrualDetail } from '../../models/accrualDashboard/poLineReceiptAccrualDetail';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { BulkUpdateResult } from '../../models/purchaseOrder/bulkUpdateResult';
import { prepareBulkSavePurchaseOrders } from './receiptingApiUtility';
import { PriorYearAccrualEmailSentDetail } from '../../models/priorYearAccrual/priorYearAccrualEmailSentDetail';
import { Hierarchy } from '../../models/hierarchy/hierarchy';
import { AccessRequest } from '../../models/user/accessRequest';
import { CorpDetails } from '../../models/priorYearAccrual/corpDetails';
import { ReversePurchaseOrderReceipt } from '../../models/grReversal/reversePurchaseOrderReceipt';
import { PurchaseOrderCheckResult } from '../../models/purchaseOrder/purchaseOrderCheckResult';
import { PriorYearAccrualUserSummary } from '../../models/priorYearAccrual/priorYearAccrualUserSummary';
import { PriorYearAccrualElevatedSummary } from '../../models/priorYearAccrual/priorYearAccrualElevatedSummary';
import { ClientNotificationItemState } from '../../models/clientNotification/clientNotificationItemState';
import { DashboardTile } from '../../models/dashboard/dashboardTile';

/**
 * Receipting api client.
 */
class ReceiptingApiClient extends ApiClientBase {
    /**
     * Constructor for the receipting service api client.
     */
    constructor() {
        super(ApiService.Receipting)
    }
    
    /**
     * Get tile info for home page.
     * @param purchaseOrderSearch Purchase order search criteria.
     * @param tileSet Tile set.
     * @returns Dashboard tile array.
     */
    public async homeTileInfo(purchaseOrderSearch: PurchaseOrderSearch, tileSet: TileSet): Promise<DashboardTile[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/${tileSet === TileSet.Home ? 'homeTileInfoUser' : 'homeTileInfoFc'}.json` : `${appConfig.current.service.receiptingApiBaseUrl}purchaseOrders/homeTileInfo`;
        return await this.postObjectReturnObjectArray<PurchaseOrderSearch, DashboardTile>(DashboardTile, apiUrl, purchaseOrderSearch, {
            tileSet: tileSet
        });
    }

    /**
     * Search for POs given search criteria.
     * @param purchaseOrderSearch Purchase order search criteria.
     * @returns Purchase order search results.
     */
    public async search(purchaseOrderSearch: PurchaseOrderSearch): Promise<PurchaseOrderSearchResults | null> {
        if (appConfig.current.service.useLocalMockData) {
            // When using mock data, return a slice of the mock data based on search metadata to simulate server side paging.
            const apiUrl: string = `${this.mockDataFolder}/search.json`;
            const data: PurchaseOrderSearchResults | null = await this.getObject<PurchaseOrderSearchResults>(PurchaseOrderSearchResults, apiUrl);
            if (data) {
                // Fake the results metadata records per page and current page index based on search metadata.
                data.metaData.recordsPerPage = purchaseOrderSearch.metaData.recordsPerPage;
                data.metaData.currentPageIndex = purchaseOrderSearch.metaData.currentPageIndex;
                // Get a slice of the mock data between start and end index.
                const startRecordIndex: number = purchaseOrderSearch.metaData.currentPageIndex === 0 ? 0 : purchaseOrderSearch.metaData.currentPageIndex * purchaseOrderSearch.metaData.recordsPerPage;
                const endRecordIndex: number = startRecordIndex + purchaseOrderSearch.metaData.recordsPerPage;
                data.results = data?.results.slice(startRecordIndex, endRecordIndex) || [];
            }
            return data;
        } else {
            const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}purchaseOrders/advancedSearch`;
            return await this.postObjectReturnObject<PurchaseOrderSearch, PurchaseOrderSearchResults>(PurchaseOrderSearchResults, apiUrl, purchaseOrderSearch);
        }
    }

    /**
     * Search for specific PO.
     * @param purchaseOrderNumber Purchase order number.
     * @param source Source.
     * @param searchOperation Search operation.
     * @param searchOperationParameter Search operation parameter.
     * @param includeReceived Included received.
     * @param poType PO type.
     * @param searchAsRole Search as role.
     * @param applySiteFilters Apply site filters.
     * @returns Purchase order search results.
     */
    public async searchForPo(
        purchaseOrderNumber: string,
        source: Source = Source.SAP,
        searchOperation: SearchOperation = SearchOperation.Nop,
        searchOperationParameter: SearchOperationParameter = SearchOperationParameter.None,
        includeReceived: boolean = true,
        poType: PoType = PoType.All,
        searchAsRole: Role = Role.User,
        applySiteFilters: boolean = true
    ): Promise<PurchaseOrderSearchResults | null> {
        if (appConfig.current.service.useLocalMockData) {
            // When using mock data, get the search.json and then get just the PO requested from it.
            const apiUrl: string = `${this.mockDataFolder}/search.json`;
            const data: PurchaseOrderSearchResults | null = await this.getObject<PurchaseOrderSearchResults>(PurchaseOrderSearchResults, apiUrl);
            if (data) {
                // If the mock data contained visibilityCheckResults then return the data as-is.
                if (data.visibilityCheckResults && data.visibilityCheckResults.length > 0) {
                    return data;
                }
                // Otherwise, look in the mock data for the PO number being searched for.
                const purchaseOrderDetails: PurchaseOrderDetails | undefined = data.results.find((purchaseOrderDetails: PurchaseOrderDetails) => {
                    if (purchaseOrderDetails.header.purchaseOrderNumber === purchaseOrderNumber) {
                        return true;
                    }
                    return false;
                })
                // And return an array of that one PO. Otherwise nothing if not found.
                if (purchaseOrderDetails) {
                    data.results = [purchaseOrderDetails];
                } else {
                    data.results = [];
                }
            }
            return data;
        } else {
            const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}purchaseOrders/${purchaseOrderNumber}`;
            return await this.getObject<PurchaseOrderSearchResults>(PurchaseOrderSearchResults, apiUrl, {
                src: source.toLowerCase(),
                op: searchOperation.toLowerCase(),
                opParam: searchOperationParameter.toLowerCase(),
                includeReceived: includeReceived,
                poType: poType,
                searchAsRole: searchAsRole.toLowerCase(),
                applySiteFilters: applySiteFilters
            });
        }
    }

    /**
     * Excel export.
     * @param purchaseOrderSearch Purchase order search.
     * @returns Response status code.
     */
    public async excelExport(purchaseOrderSearch: PurchaseOrderSearch): Promise<number> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/mockExcel.xlsx` : `${appConfig.current.service.receiptingApiBaseUrl}receipting/ExcelExport`;
        return await this.downloadBlobFileUsingPost<PurchaseOrderSearch>(apiUrl, purchaseOrderSearch);
    }

    /**
     * Excel import.
     * @param file Excel file to import.
     * @returns Returns null.
     */
    public async excelImport(file: File): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}receipting/ExcelImport`;
        return await this.postFile(apiUrl, file);
    }

    /**
     * Load user profile.
     * @param alias User alias.
     * @returns User profile.
     */
    public async loadUserProfile(alias?: string): Promise<UserProfile | null> {
        if (isNullOrUndefinedOrEmptyString(alias)) {
            alias = getUserAlias();
        }

        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/userProfile.json` : `${appConfig.current.service.receiptingApiBaseUrl}rbac/${alias}/profile`;
        return await this.getObject<UserProfile>(UserProfile, apiUrl);
    }

    /**
     * Load audit history.
     * @param purchaseOrderNumber Purchase order number.
     * @param purchaseOrderLineItemNumber Purchase order line item number.
     * @returns Array of audit history events.
     */
    public async loadAuditHistory(purchaseOrderNumber: string, purchaseOrderLineItemNumber: string): Promise<AuditHistoryEvent[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/auditHistoryEvent.json` : `${appConfig.current.service.receiptingApiBaseUrl}auditHistory/${purchaseOrderNumber}/${purchaseOrderLineItemNumber}`;
        return await this.getObjectArray<AuditHistoryEvent>(AuditHistoryEvent, apiUrl);
    }

    /**
     * Load prior year accrual user summary.
     * @param priorYearAccrualSearch Search params.
     * @returns Object containing summary.
     */
    public async loadPriorYearAccrualUserSummary(priorYearAccrualSearch: PriorYearAccrualSearch): Promise<PriorYearAccrualUserSummary | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/priorYearAccrualUserSummary.json` : `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/userSummary`;
        return await this.postObjectReturnObject<PriorYearAccrualSearch, PriorYearAccrualUserSummary>(PriorYearAccrualUserSummary, apiUrl, priorYearAccrualSearch);
    }

    /**
     * Load prior year accrual elevated (agent, accruals admin, finance controller) summary.
     * Note, this API also works for other elevated roles including Accruals Admin or Agent.
     * @param priorYearAccrualSearchRole Prior year accrual search role. When used with this elevated summary it will be Agent, AccrualAdmin, or FinanceController. Not User role.
     * @param priorYearAccrualSearch Search params.
     * @returns Object containing summary.
     */
    public async loadPriorYearAccrualElevatedSummary(priorYearAccrualSearchRole: PriorYearAccrualSearchRole, priorYearAccrualSearch: PriorYearAccrualSearch): Promise<PriorYearAccrualElevatedSummary | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/priorYearAccrualElevatedSummary.json` : `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/elevatedSummary`;
        return await this.postObjectReturnObject<PriorYearAccrualSearch, PriorYearAccrualElevatedSummary>(PriorYearAccrualElevatedSummary, apiUrl, priorYearAccrualSearch, { role: priorYearAccrualSearchRole });
    }

    /**
     * Get regions used on prior year accrual page for search filter.
     */
    public async priorYearAccrualRegions(): Promise<string[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/priorYearAccrualRegions.json` : `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/regions`;
        return await this.getValueArray<string>(apiUrl);
    }

    /**
     * Prior year accrual search.
     * @param priorYearAccrualSearch Prior year accrual search params.
     * @returns Array of prior year accrual items.
     */
    public async priorYearAccrualSearch(priorYearAccrualSearch: PriorYearAccrualSearch): Promise<PriorYearAccrualSearchResults | null> {
        if (appConfig.current.service.useLocalMockData) {
            // When using mock data, return a slice of the mock data to simulate server side paging.
            const apiUrl: string = `${this.mockDataFolder}/priorYearAccrualSearch.json`;
            const data: PriorYearAccrualSearchResults | null = await this.getObject<PriorYearAccrualSearchResults>(PriorYearAccrualSearchResults, apiUrl);
            if (data && priorYearAccrualSearch.startPage && priorYearAccrualSearch.recordsPerPage) {
                // Get a slice of the mock data between start and end index.
                const startRecordIndex: number = (priorYearAccrualSearch.startPage - 1 /* startPage is one based, not zero based. */) * priorYearAccrualSearch.recordsPerPage;
                const endRecordIndex: number = startRecordIndex + priorYearAccrualSearch.recordsPerPage;
                data.items = data.items.slice(startRecordIndex, endRecordIndex) || [];
            }
            return data;
        } else {
            const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/search`;
            return await this.postObjectReturnObject<PriorYearAccrualSearch, PriorYearAccrualSearchResults>(PriorYearAccrualSearchResults, apiUrl, priorYearAccrualSearch);
        }
    }

    /**
     * Prior year accrual export.
     * @param priorYearAccrualSearch Prior year accrual search params.
     * @returns Response status code.
     */
    public async priorYearAccrualExport(priorYearAccrualSearch: PriorYearAccrualSearch): Promise<number> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/mockExcel.xlsx` : `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/export`;
        return await this.downloadBlobFileUsingPost<PriorYearAccrualSearch>(apiUrl, priorYearAccrualSearch);
    }

    /**
     * Prior year accrual export corp details.
     * @param priorYearAccrualSearch Prior year accrual search params.
     * @returns Response status code.
     */
    public async priorYearAccrualExportCorpDetails(priorYearAccrualSearch: PriorYearAccrualSearch): Promise<number> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/mockExcel.xlsx` : `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/exportcorpdetails`;
        return await this.downloadBlobFileUsingPost<PriorYearAccrualSearch>(apiUrl, priorYearAccrualSearch);
    }

    /**
     * Prior year accrual import corp details.
     * @param file Excel file to upload.
     * @returns Returns null.
     */
    public async priorYearAccrualImportCorpDetails(file: File): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/uploadcorpdetails`;
        return await this.postFile(apiUrl, file);
    }

    /**
     * Prior year accrual retain.
     * @param purchaseOrderLineItemPairs Purchase order lines to retain.
     * @returns Null.
     */
    public async priorYearAccrualRetain(purchaseOrderLineItemPairs: PurchaseOrderLineItemPair[]): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/retain`;
        return await this.postObject<PurchaseOrderLineItemPair[]>(apiUrl, purchaseOrderLineItemPairs);
    }

    /**
     * Prior year accrual refresh.
     * @param purchaseOrderLineItemPairs Purchase order lines to refresh.
     * @returns Null.
     */
    public async priorYearAccrualRefresh(purchaseOrderLineItemPairs: PurchaseOrderLineItemPair[]): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/refresh`;
        return await this.postObject<PurchaseOrderLineItemPair[]>(apiUrl, purchaseOrderLineItemPairs);
    }

    
    /**
     * Get prior year accrual email details.
     * @param purchaseOrderNumbers Purchase order numbers.
     * @returns Array of prior year accrual email sent detail objects.
     */
    public async getPriorYearAccrualEmailDetails(purchaseOrderNumbers: number[]): Promise<PriorYearAccrualEmailSentDetail[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/getPriorYearAccrualEmailDetails.json` : `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/getEmailDetails`;
        return await this.postObjectReturnObjectArray<number[], PriorYearAccrualEmailSentDetail>(PriorYearAccrualEmailSentDetail, apiUrl, purchaseOrderNumbers);
    }

    /**
     * Trigger prior year accrual manual notification.
     * @param purchaseOrderOwners Purchase order owners.
     */
    public async triggerPriorYearAccrualManualNotification(purchaseOrderOwners: string[]): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/triggerManualNotification`;
        return await this.postObject<string[]>(apiUrl, purchaseOrderOwners);
    }

    /**
     * Update accounting details.
     * @param poLines Purchase order line item pairs.
     * @param corpDetails Corp details object.
     * @param updateMode Corp details update mode.
     */
    public async updateAccountingDetails(
        poLines: PurchaseOrderLineItemPair[],
        corpDetails: CorpDetails
    ): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/updateAccountingDetails`;
        // Post an object containing the PO line item pairs for all selected lines, and all the input values the user
        // entered into the dialog. The server code will update the line items with the values in the Cosmos collection.
        return await this.postObject<object>(apiUrl, {
            poLines,
            corpDetails
        });
    }
    
    /**
     * Get prior year accrual failures.
     */
    public async priorYearAccrualFailures(): Promise<POLinePriorYearAccrualProcessingDetail | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/priorYearAccrualFailures.json` : `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/activities`;
        return await this.getObject<POLinePriorYearAccrualProcessingDetail>(POLinePriorYearAccrualProcessingDetail, apiUrl);
    }

    /**
     * Requeue prior year accrual activity.
     * @param purchaseOrderNumber Purchase order number.
     * @param lineItemNumber Line item number.
     * @param transactionId Transaction id.
     * @returns Returns null.
     */
    public async requeuePriorYearAccrualActivity(purchaseOrderNumber: number, lineItemNumber: number, transactionId: string): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/${purchaseOrderNumber}/${lineItemNumber}/${transactionId}`;
        return await this.postObject<null>(apiUrl, null);
    }

    /**
     * Upload manual postings Excel file for prior year accruals.
     * @param file Excel file to upload.
     * @returns Returns null.
     */
    public async pyaUploadManualPostings(file: File): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}prioryearaccruals/uploadmanualpostings`;
        return await this.postFile(apiUrl, file);
    }

    /**
     * Close PO lines.
     * @param purchaseOrderLineCloseArray Purchase order lines to close.
     * @returns Null.
     */
    public async closePoLines(purchaseOrderLineCloseArray: PurchaseOrderLineClose[]): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}purchaseorders/closepolines`;
        return await this.postObject<PurchaseOrderLineClose[]>(apiUrl, purchaseOrderLineCloseArray);
    }

    /**
     * Load companies (with company codes).
     * @returns Company domain data.
     */
    public async loadCompanies(): Promise<Company[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/companies.json` : `${appConfig.current.service.receiptingApiBaseUrl}companies`;
        return await this.getObjectArray<Company>(Company, apiUrl);
    }

    /**
     * Search for suppliers by name or number match.
     * @param filter Supplier name or number to filter on.
     * @returns Supplier search results.
     */
    public async searchSuppliers(filter: string): Promise<Supplier[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/supplier.json` : `${appConfig.current.service.receiptingApiBaseUrl}supplier`;
        return await this.getObjectArray<Supplier>(Supplier, apiUrl, { name: filter });
    }

    /**
     * Save user profile.
     * @param userProfile User profile to save.
     * @returns Null.
     */
    public async saveUserProfile(userProfile: UserProfile): Promise<null> {
        // If mock data is enabled then the postObject will just simulate a delay and return null.
        const alias = getUserAlias();
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}rbac/${alias}/profile`;
        return await this.postObject<UserProfile>(apiUrl, userProfile);
    }

    /**
     * Check if user is a member of a security group.
     * @param sgName Security group name.
     * @returns True if user is a member, otherwise false.
     */
    public async sgCheck(sgName: string): Promise<boolean> {
        const alias = getUserAlias();
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/sgCheck.txt` : `${appConfig.current.service.receiptingApiBaseUrl}aadhelper/${alias}/sgCheck/${sgName}`;
        return await this.getValue<boolean>(apiUrl);
    }

    /**
     * Get daily 1010 report data.
     * @param startDate Start date.
     * @param endDate End date.
     * @returns List of report files.
     */
    public async daily1010Reports(startDate: Date, endDate: Date): Promise<ReportFile[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/daily1010Reports.json` : `${appConfig.current.service.receiptingApiBaseUrl}reports/daily/1010`;
        // Note that as of now, the server code does not actually use the end date. It just takes the year and month
        // from the start date. The api should be refactored to just pass in the year and month as numbers.
        return await this.getObjectArray<ReportFile>(ReportFile, apiUrl, {
            'startDateUtc': dateAsNonUtcIsoString(startDate),
            'endDateUtc': dateAsNonUtcIsoString(endDate)
        });
    }

    /**
     * Get daily non-1010 report data.
     * @param startDate Start date.
     * @param endDate End date.
     * @returns List of report files.
     */
    public async dailyNon1010Reports(startDate: Date, endDate: Date): Promise<ReportFile[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/dailyNon1010Reports.json` : `${appConfig.current.service.receiptingApiBaseUrl}reports/daily/non1010`;
        return await this.getObjectArray<ReportFile>(ReportFile, apiUrl, {
            'startDateUtc': dateAsNonUtcIsoString(startDate),
            'endDateUtc': dateAsNonUtcIsoString(endDate)
        });
    }

    /**
     * Get daily open accrual 1010 report data.
     * @param startDate Start date.
     * @param endDate End date.
     * @returns List of report files.
     */
    public async dailyOpenAccrual1010Reports(startDate: Date, endDate: Date): Promise<ReportFile[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/dailyOpenAccrual1010Reports.json` : `${appConfig.current.service.receiptingApiBaseUrl}reports/daily/open-accrual-1010`;
        return await this.getObjectArray<ReportFile>(ReportFile, apiUrl, {
            'startDateUtc': dateAsNonUtcIsoString(startDate),
            'endDateUtc': dateAsNonUtcIsoString(endDate)
        });
    }

    /**
     * Get daily prior year accrual report data.
     * @param startDate Start date.
     * @param endDate End date.
     * @returns List of report files.
     */
    public async dailyPriorYearAccrualReports(startDate: Date, endDate: Date): Promise<ReportFile[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/dailyPriorYearAccrualReports.json` : `${appConfig.current.service.receiptingApiBaseUrl}reports/daily/prioryearaccrualreport`;
        return await this.getObjectArray<ReportFile>(ReportFile, apiUrl, {
            'startDateUtc': dateAsNonUtcIsoString(startDate),
            'endDateUtc': dateAsNonUtcIsoString(endDate)
        });
    }

    /**
     * Get daily open accrual non-1010 report data.
     * @param startDate Start date.
     * @param endDate End date.
     * @returns List of report files.
     */
    public async dailyOpenAccrualNon1010Reports(startDate: Date, endDate: Date): Promise<ReportFile[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/dailyOpenAccrualNon1010Reports.json` : `${appConfig.current.service.receiptingApiBaseUrl}reports/daily/open-accrual-non1010`;
        return await this.getObjectArray<ReportFile>(ReportFile, apiUrl, {
            'startDateUtc': dateAsNonUtcIsoString(startDate),
            'endDateUtc': dateAsNonUtcIsoString(endDate)
        });
    }

    /**
     * Download report file.
     * @param reportLink Report link.
     * @returns Response status code.
     */
    public async downloadReportFile(reportLink: string): Promise<number> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/mockExcel.xlsx` : `${appConfig.current.service.receiptingApiBaseUrl}reports/downloadreport`;
        return await this.downloadBlobFileUsingGet(apiUrl, { reportLink: reportLink });
    }

    /**
     * Gets existing client notifications.
     * @returns Existing notifications.
     */
    public async existingNotifications(): Promise<ClientNotificationData | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/existingNotifications.json` : `${appConfig.current.service.receiptingApiBaseUrl}clientnotification/existingnotifications`;
        return await this.getObject<ClientNotificationData>(ClientNotificationData, apiUrl);
    }

    /**
     * Dismiss a notification.
     * @param notificationId Notification id to dismiss.
     * @returns Returns null.
     */
    public async dismissNotification(notificationId): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}clientnotification/dismissnotification`;
        const notificationData: { notificationId: string } = {
            notificationId: notificationId
        };
        return await this.postObject<{ notificationId: string }>(apiUrl, notificationData);
    }

    /**
     * Dismiss all notifications.
     * @returns Returns null.
     */
    public async dismissAllNotifications(clientNotificationItemState?: ClientNotificationItemState): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}clientnotification/dismissallnotifications`;
        const dismissData: { clientNotificationItemState: ClientNotificationItemState | undefined} = {
            clientNotificationItemState: clientNotificationItemState
        }
        return await this.postObject<{ clientNotificationItemState: ClientNotificationItemState | undefined}>(apiUrl, dismissData);
    }

    /**
     * Get accrual failures.
     */
    public async accrualFailures(): Promise<POLineAccrualProcessingDetail | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/accrualFailures.json` : `${appConfig.current.service.receiptingApiBaseUrl}accruals/activities?state=unprocessed`;
        return await this.getObject<POLineAccrualProcessingDetail>(POLineAccrualProcessingDetail, apiUrl);
    }

    /**
     * Get accruals out of sync.
     */
    public async accrualsOutOfSync(): Promise<POLineReceiptAccrualDetail | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/accrualsOutOfSync.json` : `${appConfig.current.service.receiptingApiBaseUrl}accruals?state=notsynchronized`;
        return await this.getObject<POLineReceiptAccrualDetail>(POLineReceiptAccrualDetail, apiUrl);
    }

    /**
     * Requeue accrual activity.
     * @param purchaseOrderNumber Purchase order number.
     * @param lineItemNumber Line item number.
     * @param transactionId Transaction id.
     * @returns Returns null.
     */
    public async requeueAccrualActivity(purchaseOrderNumber: number, lineItemNumber: number, transactionId: string): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}accruals/${purchaseOrderNumber}/${lineItemNumber}/${transactionId}`;
        return await this.postObject<null>(apiUrl, null);
    }

    /**
     * Gets line item accrual object.
     * @param purchaseOrderNumber Purchase order number.
     * @param lineItemNumber Line item number.
     * @returns Line item accrual object.
     */
    public async lineItemAccrual(purchaseOrderNumber: number, lineItemNumber: number): Promise<Record<string, unknown> | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/lineItemAccrual.json` : `${appConfig.current.service.receiptingApiBaseUrl}accruals/${purchaseOrderNumber}/${lineItemNumber}`;
        // Note that as of now we will not model this object in the client. But rather will be plain JSON object.
        return await this.getObject<any>(Object, apiUrl);
    }

    /**
     * Gets line item accrual audit history object.
     * @param purchaseOrderNumber Purchase order number.
     * @param lineItemNumber Line item number.
     * @returns Line item accrual audit history.
     */
    public async lineItemAccrualAuditHistory(purchaseOrderNumber: number, lineItemNumber: number): Promise<Record<string, unknown> | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/lineItemAccrualAuditHistory.json` : `${appConfig.current.service.receiptingApiBaseUrl}accruals/audit/${purchaseOrderNumber}/${lineItemNumber}`;
        // Note that as of now we will not model this object in the client. But rather will be plain JSON object.
        return await this.getObject<any>(Object, apiUrl);
    }

    /**
     * Loads hierarchy.
     * @returns Hierarchy data tree.
     */
    public async loadHierarchy(): Promise<Hierarchy | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/hierarchy.json` : `${appConfig.current.service.receiptingApiBaseUrl}hierarchy`;
        // Note that as of now we will not model this object in the client. But rather will be plain JSON object.
        return await this.getObject<Hierarchy>(Hierarchy, apiUrl);
    }

    /**
     * Saves PO data in bulk.
     * *********************************************************************************************************************
     * Note this API needs major refactoring and should be replaced. New API design should put messages into queues for
     * updating goods line updates and receipt, service line updates, updating details on existing GR goods line. Close line
     * functionality has already been moved out to a separate api that works with storage queue / function app.
     * Once the redesigned API is available, switch client code to that and delete this one.
     * *********************************************************************************************************************
     * @param lineItems Line items to save.
     */
    public async bulkSavePurchaseOrders(lineItems: PurchaseOrderLineItem[]): Promise<BulkUpdateResult | null> {
        const saveLineItems: PurchaseOrderLineItem[] = prepareBulkSavePurchaseOrders(lineItems);

        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/bulkSavePurchaseOrders.json` : `${appConfig.current.service.receiptingApiBaseUrl}receipting/BulkSavePurchaseOrders`;
        return await this.postObjectReturnObject<PurchaseOrderLineItem[], BulkUpdateResult>(BulkUpdateResult, apiUrl, saveLineItems);
    }

    /**
     * Submit access request.
     * @param accessRequest Access request.
     * @returns Returns null.
     */
    public async submitAccessRequest(accessRequest: AccessRequest): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}accessrequest/submitaccessrequest`;
        return await this.postObject<AccessRequest>(apiUrl, accessRequest);
    }

    /**
     * Load finance controller access request history.
     * @returns Finance controller access request history.
     */
    public async loadFinanceControllerAccessRequestHistory(): Promise<AccessRequest[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/financeControllerAccessRequestHistory.json` : `${appConfig.current.service.receiptingApiBaseUrl}accessrequest/financecontroller/history`;
        return await this.getObjectArray<AccessRequest>(AccessRequest, apiUrl);
    }

    /**
     * Load access requests for agent.
     * @returns Access requests for agent.
     */
    public async loadAccessRequestsForAgent(): Promise<AccessRequest[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/accessRequestsForAgent.json` : `${appConfig.current.service.receiptingApiBaseUrl}accessrequest/getaccessrequests`;
        return await this.getObjectArray<AccessRequest>(AccessRequest, apiUrl);
    }

    /**
     * Load access requests for finance controller.
     * @returns Access requests for finance controller.
     */
    public async loadAccessRequestsForFinanceController(): Promise<AccessRequest[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/accessRequestsForFinanceController.json` : `${appConfig.current.service.receiptingApiBaseUrl}accessrequest/financecontroller`;
        return await this.getObjectArray<AccessRequest>(AccessRequest, apiUrl);
    }

    /**
     * Approve access request.
     * @param accessRequest Access request.
     * @returns Nothing.
     */
    public async approveAccessRequest(accessRequest: AccessRequest): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}accessrequest/approve/${accessRequest.id}`;
        return await this.postObject<AccessRequest>(apiUrl, accessRequest);
    }

    /**
     * Reject access request.
     * @param accessRequest Access request.
     * @returns Nothing.
     */
    public async rejectAccessRequest(accessRequest: AccessRequest): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingApiBaseUrl}accessrequest/reject/${accessRequest.id}`;
        return await this.postObject<AccessRequest>(apiUrl, accessRequest);
    }

    /**
     * Reverse goods receipt.
     * @param grReversalRequest Goods receipt reversal request.
     * @returns Array of reversal receipt objects.
     */
    public async reverseGoodsReceipt(grReversalRequest: ReversePurchaseOrderReceipt[]): Promise<ReversePurchaseOrderReceipt[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/reversePurchaseOrderReceipt.json` : `${appConfig.current.service.receiptingApiBaseUrl}receipt/reverse`;
        return await this.postObjectReturnObjectArray<ReversePurchaseOrderReceipt[], ReversePurchaseOrderReceipt>(ReversePurchaseOrderReceipt, apiUrl, grReversalRequest);
    }

    /**
     * Purchase order check. Checks to see if a user has access to a PO and if not, why not.
     * @param purchaseOrderNumber Purchase order number.
     * @param userAlias User alias.
     * @param userAadOid User AAD object id.
     * @returns Purchase order check results.
     */
    public async purchaseOrderCheck(purchaseOrderNumber: string, userAlias: string, userAadOid: string): Promise<PurchaseOrderCheckResult[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/purchaseOrderCheck.json` : `${appConfig.current.service.receiptingApiBaseUrl}purchaseorders/checks/${purchaseOrderNumber}`;
        return await this.getObjectArray<PurchaseOrderCheckResult>(PurchaseOrderCheckResult, apiUrl, {
            userAlias: userAlias,
            userAadOid: userAadOid
        });
    }
}

export const receiptingApiClient = new ReceiptingApiClient();
